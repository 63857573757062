import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("./locales/en/translation.json") },
      ar: { translation: require("./locales/ar/translation.json") },
    },
    returnObjects: true,
    lng: localStorage.getItem("language") || "en", // Default language or saved language
    fallbackLng: "en", // Fallback to English if translation is missing
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    debug: true, // Enable for debugging purposes
  });

export default i18n;
