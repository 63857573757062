import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <footer className="site-footer footer-sticky">
            <div className="site-footer__top">
                <div className="site-footer-shape-1" style={{backgroundImage: `url('/assets/img/alriyada-bg.svg')`}}></div>
                <div className="footer-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="footer-widget__column footer-widget__about">
                                <div className="footer-widget__logo">
                                    <Link to="/" onClick={handleScrollToTop}>
                                        <img src="/assets/img/alriyada-logo-w.svg" alt="AlRiyada White Logo" width="160"/>
                                    </Link >
                                </div>
                                <div className="footer-widget__about-text-box">
                                    <p className="footer-widget__about-text">{t('footer-paragraph')}</p>
                                </div>
                                <div className="site-footer__social">
                                    <Link to="https://www.facebook.com/profile.php?id=61569704330778" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="https://www.instagram.com/alriyada_om/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link>
                                    <Link to="https://www.tiktok.com/@alriyada_om" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></Link>
                                    <Link to="https://x.com/alriyada_" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></Link>
                                    <Link to="https://www.youtube.com/channel/UC6RL2DqtTSHiI5ZmL8F0Mig" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12"></div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="footer-widget__column footer-widget__explore clearfix">
                                <h3 className="footer-widget__title">{t('footer-title-1')}</h3>
                                <ul className="footer-widget__explore-list list-unstyled clearfix">
                                    <li><Link to="/" onClick={handleScrollToTop}>{t('menu-1')}</Link></li>
                                    <li><Link to="/about" onClick={handleScrollToTop}>{t('menu-2')}</Link></li>
                                    <li><Link to="/services" onClick={handleScrollToTop}>{t('menu-3')}</Link></li>
                                    <li><Link to="/contact" onClick={handleScrollToTop}>{t('menu-4')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="footer-widget__column footer-widget__contact clearfix">
                                <h3 className="footer-widget__title">{t('footer-title-2')}</h3>
                                <p className="footer-widget__contact-text">{t('address')}</p>
                                <h4 className="footer-widget__contact-info">
                                    <Link to="tel:0096894040974" className="footer-widget__contact-number d-block"> {t('phone')}</Link>
                                    <Link to="mailto:contact@alriyada.pro" className="footer-widget__contact-email">{t('mail')}</Link >
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">{t('copyright')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer