import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../contexts/LanguageContext";

const Header = ({showSecondNav}) => {
  const { language, switchLanguage } = useLanguage();
  const { t, i18n } = useTranslation();
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);
  const [isBodyLocked, setIsBodyLocked] = useState(false);
  const [localStorageValue, setlocalStorageValue] = useState(localStorage.getItem("language"));

  const mobileToggler = () => {
    setIsMobileNavExpanded(!isMobileNavExpanded);
    setIsBodyLocked(!isBodyLocked);
  };
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const handleSwitchLanguage = (lng) => {
    setlocalStorageValue(lng);
    switchLanguage(lng);
  };
  useEffect(() => {
    const localStorageLang = localStorage.getItem("language");
    if (localStorageLang) {
      changeLanguage(localStorageLang);
    }
  }, [localStorageValue]);

  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
  }, [i18n.language]);
  
  return (
    <>
      <header className="main-header clearfix">
        <nav className="main-menu clearfix">
          <div className="main-menu-wrapper clearfix">
            <div className="main-menu-wrapper__left">
              <div className="main-menu-wrapper__logo">
                <NavLink to="/" onClick={handleScrollToTop}>
                  <img className="header-logo" src="/assets/img/alriyada-logo.svg" alt="" />
                </NavLink>
              </div>
              <div className="main-menu-wrapper__main-menu">
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <button className={`mobile-nav__button ${localStorageValue === "en" ? "active" : ""}`} onClick={() => handleSwitchLanguage("en")}><img src="/assets/img/lang/flag-en.svg" width="25" alt="AlRiyada English" /></button>
                  <button className={`mobile-nav__button ${localStorageValue === "ar" ? "active" : ""}`} onClick={() => handleSwitchLanguage("ar")}><img src="/assets/img/lang/flag-ar.svg" width="25" alt="AlRiyada Arabic" /></button>
                  <NavLink type="button" className="mobile-nav__toggler" onClick={mobileToggler}>
                    <i className="fa fa-bars"></i>
                  </NavLink>
                </div>
                <ul className="main-menu__list">
                  <li className="active">
                    <NavLink to="/" onClick={handleScrollToTop}>{t('menu-1')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about" onClick={handleScrollToTop}>{t('menu-2')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services" onClick={handleScrollToTop}>{t('menu-3')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact" onClick={handleScrollToTop}>{t('menu-4')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main-menu-wrapper__right">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button className={`${localStorageValue === "en" ? "active" : ""}`} onClick={() => handleSwitchLanguage("en")}><img src="/assets/img/lang/flag-en.svg" width="25" alt="AlRiyada English" /></button>
                <button className={`${localStorageValue === "ar" ? "active" : ""}`} onClick={() => handleSwitchLanguage("ar")}><img src="/assets/img/lang/flag-ar.svg" width="25" alt="AlRiyada Arabic" /></button>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content">
          <nav className="main-menu clearfix">
          <div className="main-menu-wrapper clearfix">
            <div className="main-menu-wrapper__left">
              <div className="main-menu-wrapper__logo">
                <NavLink to="/">
                  <img className="header-logo" src="/assets/img/alriyada-logo.svg" alt="" />
                </NavLink>
              </div>
              <div className="main-menu-wrapper__main-menu">
                <NavLink type="button" className="mobile-nav__toggler" onClick={mobileToggler}>
                  <i className="fa fa-bars"></i>
                </NavLink>
                <ul className="main-menu__list">
                  <li className="active">
                    <NavLink to="/" onClick={handleScrollToTop}>{t('menu-1')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about" onClick={handleScrollToTop}>{t('menu-2')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services" onClick={handleScrollToTop}>{t('menu-3')}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact" onClick={handleScrollToTop}>{t('menu-4')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main-menu-wrapper__right">
              <div className="d-flex justify-content-center align-items-center gap-2">
              <button className={`${localStorageValue === "en" ? "active" : ""}`} onClick={() => handleSwitchLanguage("en")}><img src="/assets/img/lang/flag-en.svg" width="25" alt="AlRiyada English" /></button>
              <button className={`${localStorageValue === "ar" ? "active" : ""}`} onClick={() => handleSwitchLanguage("ar")}><img src="/assets/img/lang/flag-ar.svg" width="25" alt="AlRiyada Arabic" /></button>
              </div>
            </div>
          </div>
          </nav>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${isMobileNavExpanded ? 'expanded' : ''}`}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler" onClick={mobileToggler}><i className="fa fa-times"></i></span>
            <div className="logo-box">
                <NavLink to="index.html" aria-label="logo image"><img src="/assets/img/alriyada-logo-w.svg" width="155" alt="AlRiyada Logo" /></NavLink>
            </div>
            <div className="mobile-nav__container">
              <ul className="main-menu__list">
                <li className="active">
                  <NavLink to="/" onClick={mobileToggler}>{t('menu-1')}</NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={mobileToggler}>{t('menu-2')}</NavLink>
                </li>
                <li>
                  <NavLink to="/services" onClick={mobileToggler}>{t('menu-3')}</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={mobileToggler}>{t('menu-4')}</NavLink>
                </li>
              </ul>
            </div>
        </div>
      </div>

    {isBodyLocked && <style>{'body { overflow: hidden; }'}</style>}
    </>
  );
};

export default Header;
