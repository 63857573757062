import { Suspense, lazy, useEffect, useState } from "react";
import jQuery from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./commun/Header";
import Footer from "./commun/Footer";
import Gototop from "./pages/others/Scrolltop";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "animate.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import smoothscroll from 'smoothscroll-for-websites';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { ElfsightWidget } from 'react-elfsight-widget';
import MetaTags from "./pages/others/MetaTags";

const Home = lazy(() => import("./pages/components/Home"));
const About = lazy(() => import("./pages/components/About"));
const Services = lazy(() => import("./pages/components/Services"));
const ServiceDetails = lazy(() => import("./pages/components/ServiceDetails"));
const Contact = lazy(() => import("./pages/components/Contact"));
const Page404 = lazy(() => import("./pages/components/Page404"));
const Loader = <div className="preloader"><img className="preloader__image" width="180" src="/assets/img/alriyada-loader.gif" alt="AlRiyada Loader" /></div>

function App() {
  const [showSecondNav, setShowSecondNav] = useState(false)
  useEffect(() => {
		jQuery(window).on("scroll", function () {
      if (jQuery(".stricked-menu").length) {
        var headerScrollPos = 130;
        var stricky = jQuery(".stricked-menu");
        if (jQuery(window).scrollTop() > headerScrollPos) {
          stricky.addClass("stricky-fixed");
        } else if (jQuery(this).scrollTop() <= headerScrollPos) {
          stricky.removeClass("stricky-fixed");
        }
      }
      if (jQuery(".sticky-header__content").length) {
          setShowSecondNav(true)
        } else {
          setShowSecondNav(false)
      }
    });
    smoothscroll({
      frameRate: 150,
      animationTime: 1200,
      stepSize: 100,
    });
	}, [])
  
  return (
    <>
    <Suspense fallback={Loader}>
      <MetaTags />
      <div className="page-wrapper">
        <Router>
          <main>
          <Header showSecondNav={showSecondNav} />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/service-details/:slug" element={<ServiceDetails />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </main>
          <Footer />
          <Gototop />
        </Router>
        <ElfsightWidget widgetId={"b71fbfc0-42cf-4121-9db0-68d6ce64c732"} lazy modern/>;
      </div>
    </Suspense>
    </>
  );
}

export default App;
